"use client"

import { createContext, PropsWithChildren, useContext } from "react"

import { resolveTenantFromSession } from "@/helpers/operator/tenant"

type TenantContextValue = {
  tenant: Awaited<ReturnType<typeof resolveTenantFromSession>>
}

const TenantContext = createContext<TenantContextValue | undefined>(undefined)

export function useTenant() {
  const ctx = useContext(TenantContext)
  return ctx?.tenant
}

export function useSafeTenant() {
  const tenant = useTenant()
  if (!tenant) {
    throw new Error("tenant is not available")
  }

  return { ...tenant, tenantId: tenant.id }
}

export function TenantProvider(props: PropsWithChildren<TenantContextValue>) {
  return <TenantContext.Provider value={{ tenant: props.tenant }}>{props.children}</TenantContext.Provider>
}
