"use client"

import { TooltipProvider } from "@deltagreen/react-library"
import Decimal from "decimal.js"
import { LazyMotion } from "framer-motion"
import { Provider as JotaiProvider } from "jotai"
import { PropsWithChildren } from "react"
import superjson from "superjson"

import { SessionProvider } from "@/components/SessionProvider"
import { TenantProvider } from "@/components/TenantProvider"
import { resolveTenantFromSession } from "@/helpers/operator/tenant"
import { getServerSession } from "@/server/session"

superjson.registerCustom<Decimal, string>(
  {
    isApplicable: (v): v is Decimal => Decimal.isDecimal(v),
    serialize: (v) => v.toJSON(),
    deserialize: (v) => new Decimal(v),
  },
  "decimal.js",
)

type Props = {
  session: Awaited<ReturnType<typeof getServerSession>>
  tenant: Awaited<ReturnType<typeof resolveTenantFromSession>>
}

export function GlobalProvider(props: PropsWithChildren<Props>) {
  const { session, tenant, children } = props
  return (
    <JotaiProvider>
      <LazyMotion features={async () => (await import("@/components/framerMotionFeatures")).default} strict>
        <SessionProvider session={session}>
          <TenantProvider tenant={tenant}>
            <TooltipProvider>{children}</TooltipProvider>
          </TenantProvider>
        </SessionProvider>
      </LazyMotion>
    </JotaiProvider>
  )
}
